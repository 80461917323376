import { roomReducer, roomState } from "./roomReducer";
import { Api } from "../utils/api";
import { questionsReducer, QuestionsState } from "./questionsReducer";
import { authReducer, AuthState } from "./authReducer";
import thunk from 'redux-thunk'

import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';


export const initStore = (api: Api) => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: { api }
  });

  const rootReducer = combineReducers({
    auth: authReducer,
    room: roomReducer,
    questions: questionsReducer,
  });


  const store = createStore(rootReducer, compose(applyMiddleware(thunk, epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}


export interface RootState {
  room: roomState
  questions: QuestionsState,
  auth: AuthState,
};
