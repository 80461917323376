import axios, {AxiosInstance} from 'axios';
import { Room } from "../types/domain/room";
import { Answer, SavedAnswer } from "../types/domain/answer";
import { AuthState } from "../store/authReducer";


export interface ApiData<T> {
  data: {
    id: number;
    attributes: T;
  };
}

export interface ApiDataArray<T> {
  data: {
    id: number;
    attributes: T;
  }[];
}


export class Api {

  axios: AxiosInstance;
  axios2: AxiosInstance;


  constructor() {
    this.axios = axios.create(
      {
        baseURL: '/back1/api'
      }
    );

    this.axios2 = axios.create(
      {
        baseURL: '/api2'
      }
    );
  }

  setAuthToken(token: string) {
    this.axios2.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  async fetchRooms(): Promise<Room[]> {

    const { data } =  await this.axios.get('/rooms?locale=ru')

    console.log('data', data);
    return data.data;
  }

  async fetchOneRoom(id: number): Promise<Room> {

    const { data } =  await this.axios.get(`/rooms/${id}?populate[tasks][populate][0]=questions`);

    return data.data;
  }


  async fetchRoomAnswers(roomId: number): Promise<SavedAnswer[]> {
    const { data } =  await this.axios2.get(`/answers/${roomId}`);

    return data.answers;
  }

  async fetchCheckAnswer(answer: Answer): Promise<boolean> {

    const { data } = await this.axios2.post(`/check`, {
        ...answer,
        locale: 'en'
      });

    console.log('data', data);

    return data.state === 'success';
  }

  async checkAuth(): Promise<AuthState> {
    const { data } = await this.axios2.post(`/auth-check`, {
        locale: 'en'
      });

    return data;

  }

  async getAuth(): Promise<AuthState> {
    const { data } = await this.axios2.post(`/auth`, {
        locale: 'en'
      });

    return data;

  }



}
