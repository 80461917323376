import { Reducer } from "redux";
import { FETCH_ONE_ROOM_SUCCESS, FETCH_ROOMS_SUCCESS } from "../actions";
import { Room } from "../types/domain/room";
import { SavedAnswer } from "../types/domain/answer";

export interface roomState {
  rooms: Room[],
  activeRoom?: Room,
}

const getDefaultState = (): roomState => {

  return {
    rooms: [],
    activeRoom: undefined,
  }

}

export const roomReducer: Reducer<roomState> = (state = getDefaultState(), action: any)   => {
  switch (action.type) {
    case (FETCH_ROOMS_SUCCESS): {
      return {
        ...state,
        rooms: action.payload.rooms,
      }
    }
    case (FETCH_ONE_ROOM_SUCCESS): {
      return {
        ...state,
        activeRoom: action.payload.room,
      }
    }
    default:
      return state;
  }
}
