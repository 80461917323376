import React from 'react';
import { BsFillPersonFill, BsBoxArrowRight } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import { useTranslation } from "react-i18next";
import { cn } from '@bem-react/classname'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Header.css'

const cnHeader = cn('Header');
interface IProps {
}

export const Header: React.FC<IProps> = (props) => {

  const { t } = useTranslation();
  // const userName = 'Elchenkov Artur';
  const userName = null;

  const action = userName ? (
    <div>
      <BsFillPersonFill/>
      <div>
        {userName}
      </div>
      <BsFillPersonFill/>
    </div>
  ) : (
    <div>
      <Button variant="outline-primary">
        {t('login')}
      </Button>
    </div>
  )
  return (
    <Container>
      <Row className={cnHeader()}>
        <Col md={'auto'}>
          logo
        </Col>

        <Col md={'auto'}>
        {action}
        </Col>
      </Row>
    </Container>
  )
}

