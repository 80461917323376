import React, { useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RoomCard } from "../RoomCard/RoomCard";
import { cn } from '@bem-react/classname'
import { useNavigate } from "react-router-dom";

import './PageRoomList.css'
import { useDispatch, useSelector } from "react-redux";
import { fetchRooms } from "../../actions";
import { RootState } from "../../store/store";

const cnPageRoomsList = cn('PageRoomsList');

interface IProps {
}

export const PageRoomsList: React.FC<IProps> = (props) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { rooms } = useSelector((state: RootState) => state.room);

  const onNavigate = useCallback((path: string) => {
    navigate(path);
  },[]);

  useEffect(() => {
    dispatch(fetchRooms())
  },[]);

  const roomsRender = rooms.map(item => {
    return (
      <Col xs lg={'4'} key={item.id.toString()}>
        <RoomCard roomId={item.id}
                  roomData={item}
                  onNavigate={onNavigate}/>
      </Col>
    )
  });

  return (
    <Container className={cnPageRoomsList()}>
      <Row>
        {roomsRender}
      </Row>
    </Container >
  )
}

