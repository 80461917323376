import React, { useEffect } from 'react';

import { Header } from "../Header/Header";
import { router } from "../../router/router";
import { useDispatch, useSelector } from "react-redux";

import {
  RouterProvider,
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { handleCheckAuth } from "../../actions";
import { RootState } from "../../store/store";
import Spinner from 'react-bootstrap/Spinner';

export const Page = () => {

  return <RouterProvider router={router} />;

}


function App() {

  const dispatch = useDispatch();
  const { token0 } = useSelector((state: RootState) => state.auth);

  useEffect(() => {

    setTimeout(() => {
      console.log('token0', token0);
      dispatch(handleCheckAuth(token0))
    }, 100)
  },[])

  console.log('token0', token0);
  const content = token0 ? <Page/> : <Spinner/>;

  return (
      <div className="App">
        <Header/>
        {content}
      </div>
  );
}

export default App;
