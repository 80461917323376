import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of, EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, mergeMap, map } from 'rxjs/operators';
import {
  empty,
  FETCH_ANSWER,
  FETCH_ONE_ROOM, FETCH_ROOM_ANSWERS,
  FETCH_ROOMS, fetchCheckAnswerDone,
  fetchOneRoomSuccess, fetchRoomAnswers, fetchRoomAnswersDone,
  fetchRoomsSuccess, getAuth, HANDLE_CHECK_AUTH, HANDLE_GET_AUTH,
  HANDLE_GLOBAL_ERROR, setAuthData
} from "../../actions";
import { Api } from "../../utils/api";
import { RootState } from "../store";
import { Room } from "../../types/domain/room";
import { AuthState } from "../authReducer";
import { SavedAnswer } from "../../types/domain/answer";

export type MainEpic = Epic<any, any, any, { api: Api }>;

const fetchRoomsEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(FETCH_ROOMS),
  mergeMap(() => from(api.fetchRooms()).pipe(
    map((response: Room[]) => fetchRoomsSuccess(response)),
    catchError(
      ofType(HANDLE_GLOBAL_ERROR)
    )
  ))
);

const fetchOneRoomEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(FETCH_ONE_ROOM),
  mergeMap((action: any) => from(api.fetchOneRoom(action.payload.id)).pipe(
    mergeMap((response: Room) => of(
      fetchOneRoomSuccess(response),
      fetchRoomAnswers(action.payload.id))),
    catchError(
      ofType(HANDLE_GLOBAL_ERROR)
    )
  ))
);

const fetchCheckAnswerEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(FETCH_ANSWER),
  mergeMap((action: any) => from(api.fetchCheckAnswer(action.payload.data)).pipe(
    map((response: any) => fetchCheckAnswerDone({ id: action.payload.data.questionId, done: response })),
    catchError(
      ofType(HANDLE_GLOBAL_ERROR)
    )
  ))
);

const checkAuthEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(HANDLE_CHECK_AUTH),
  mergeMap((action: any) => {
    if (action.payload.token0) {

      return from(api.checkAuth()).pipe(
        mergeMap((response: AuthState) => EMPTY
        ),
        catchError(() => of(getAuth()))
      )
    }

    return of(getAuth());
  })
);

const fetchRoomAnswerEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(FETCH_ROOM_ANSWERS),
  mergeMap((action) => from(api.fetchRoomAnswers(action.payload)).pipe(
    map((answers: SavedAnswer[]) => fetchRoomAnswersDone(answers)),
    catchError(
      ofType(HANDLE_GLOBAL_ERROR)
    )
  ))
);

const fetchGetAuthEpic: MainEpic = (action$, state$, { api }) => action$.pipe(
  ofType<any, any>(HANDLE_GET_AUTH),
  mergeMap(() => from(api.getAuth()).pipe(
    map((response: AuthState) => setAuthData(response)),
    catchError(
      ofType(HANDLE_GLOBAL_ERROR)
    )
  ))
);

const changeAuthDataEpic: MainEpic = (action$, state$, { api }) => state$.pipe(
  map((state: RootState): string | undefined => {
    return state?.auth?.token0;
  }),
  distinctUntilChanged((t1, t2) => t1 === t2),
  mergeMap((token0: string | undefined) => {
    if (token0) {
      api.setAuthToken(token0)
      localStorage.setItem('token0', token0);
    }

    return EMPTY;
  }),
)

export const rootEpic = combineEpics(
  fetchRoomsEpic,
  fetchOneRoomEpic,
  fetchCheckAnswerEpic,
  fetchGetAuthEpic,
  checkAuthEpic,
  changeAuthDataEpic,
  fetchRoomAnswerEpic
);
