import { Reducer } from "redux";
import { FETCH_ANSWER_DONE, FETCH_ONE_ROOM_SUCCESS, FETCH_ROOM_ANSWERS_DONE } from "../actions";
import { Room } from "../types/domain/room";
import { SavedAnswer } from "../types/domain/answer";

export interface QuestionsItem {
  id: number,
  question: string,
  answer: {
    text: string,
    done: boolean,
  }
}

export type QuestionsState = Record<QuestionsItem['id'],QuestionsItem>;

const getDefaultState = (): QuestionsState  => {

  return {}

}


const getArrayQuestions = (data: Room): QuestionsState => {

  try {

    const tasks: QuestionsState = {};


    data.attributes.tasks?.data.forEach(item => {
      item.attributes.questions.data.forEach(item => {

        tasks[item.id] = {
          id: item.id,
          question: item.attributes.question,
          answer: {
            text: '',
            done: false,
          }
        }
      })
    })

    return tasks;


  } catch (e) {
    return {};
  }

}

export const questionsReducer: Reducer<QuestionsState> = (state = getDefaultState(), action: any) => {
  switch (action.type) {

    case (FETCH_ROOM_ANSWERS_DONE): {

      const newState = {...state};

      action.payload.forEach((item : SavedAnswer) => {
        newState[item.question_id] = {
          ...state[action.payload.question_id],
          answer: {
            text: item.answer,
            done: true,
          }
        }
      })

      return newState;

    }
    case (FETCH_ANSWER_DONE): {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          answer: {
            ...state[action.payload.id].answer,
            done: action.payload.done
          }
        }
      };
    }

    case (FETCH_ONE_ROOM_SUCCESS): {
      const newQuestionsState = getArrayQuestions(action.payload.room)

      return newQuestionsState;
    }

    default:
      return state;
  }
}
