import React, { useCallback, useEffect, FC, useState, ChangeEventHandler, ChangeEvent } from 'react';
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ReactMarkdown from 'react-markdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';

import { cn } from "@bem-react/classname";

import './PageRoom.css';
import { fetchCheckAnswer, fetchOneRoom } from "../../actions";
import { useTranslation } from "react-i18next";
import { Answer } from "../../types/domain/answer";
import { FormControlProps } from "react-bootstrap/FormControl";

const cnPageRoom = cn('PageRoom');

interface IProps {
}

interface QuestionProps {
  question: string,
  answer?: string,
  id: number,
  roomId: number,
  onSubmit: (data: Answer) => void;
  done: boolean,
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export const Question: FC<QuestionProps> = props => {

  const { t } = useTranslation();

  const {
    question,
    id,
    roomId,
    onSubmit,
    done,
    answer
  } = props;

  const [answerText, setAnswer] = useState(answer || '');

  const onSubmitHandle = useCallback(() => {
    onSubmit({
      questionId: id,
      roomId,
      answer: answerText
    })
  },[answerText]);

  useEffect(() => {
    setAnswer(answer || '');
  },[answer])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAnswer(event.target.value)
  },[setAnswer])

  const variantBtn = done ? 'success' : "outline-primary"
  return(
    <div className={cnPageRoom('Question')}>
      <div className={cnPageRoom('QuestionText')}>
        {question}
      </div>
      <div className={cnPageRoom('QuestionForm')}>
        <Form.Control
          type="text"
          disabled={done}
          value={answerText}
          onChange={handleChange}
          className={cnPageRoom('QuestionInput')}
          placeholder=""
        />
        <Button
          onClick={onSubmitHandle}
          variant={variantBtn}>
          {t('submit')}
        </Button>
      </div>
    </div>
  )
}

export const PageRoom: React.FC<IProps> = props => {

  const { activeRoom } = useSelector((state: RootState) => state.room);
  const questions = useSelector((state: RootState) => state.questions);

  const dispatch = useDispatch();
  const name = 'test';
  const params = useParams();


  const onAnswer = useCallback((data: Answer) => {
    dispatch(fetchCheckAnswer(data))
  },[])

  console.log('questions', questions);

  // TODO выпилить этот useEffect — перенести в эпик
  useEffect(() => {
    const id = parseInt(params?.id || "0");
    if (!activeRoom && id) {
      dispatch(fetchOneRoom(id))
    }
  },[activeRoom])

  if (!activeRoom) return null;

  const tasksRender = activeRoom.attributes.tasks ? (
    activeRoom.attributes.tasks.data.map((item, index) => {

      const renderQuestions = item.attributes.questions.data.map(item => {
        return (
          <Question
            key={item.attributes.question}
            question={item.attributes.question}
                    id={item.id}
                    roomId={activeRoom.id}
                    onSubmit={onAnswer}
            done={Boolean(questions[item.id]?.answer.done)}
            answer={questions[item.id]?.answer.text}
          />
        )
      });
      return (
        <Accordion.Item eventKey={item.id.toString()}>

            <Accordion.Header>
              { item.attributes.name }
            </Accordion.Header>
          <Accordion.Body>
            <div className={cnPageRoom('Task')}>
              <div>
                <ReactMarkdown >
                { item.attributes.content }
                </ReactMarkdown>
              </div >
              <div className={cnPageRoom('QuestionList')}>
                {renderQuestions}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      )
    })
  ) : null;


  return (
    <Container >
      <div className={cnPageRoom('RoomName')}>
        { activeRoom.attributes.name }
      </div >
      <div className={cnPageRoom('RoomDescription')}>
      { activeRoom.attributes.description }
      </div>
      <Accordion defaultActiveKey="0">
      { tasksRender }
      </Accordion>
    </Container >
  )
}

