import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import img from './bc.jpg';
import { getRoomPath } from "../../router/router";
import { Room } from "../../types/domain/room";
import { fetchOneRoom } from "../../actions";
import { useDispatch } from "react-redux";

interface IProps {
  onNavigate: (path: string) => void;
  roomId: number,
  roomData: Room,
}

export const RoomCard: React.FC<IProps> = props => {

  const { onNavigate, roomId, roomData } = props;
  const dispatch = useDispatch();

  const {name, description } = roomData.attributes;

  const goToRoom = useCallback(() => {
    onNavigate(getRoomPath(roomId));
    dispatch(fetchOneRoom(roomId))
  },[onNavigate, roomId]);

  return (
    <Card>
      <Card.Img variant="top" src={img} />
      <Card.Body>
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          {description}
        </Card.Text>
        <Button
          onClick={goToRoom}
          variant="primary">Go somewhere</Button>
      </Card.Body>
    </Card>
  )
}

