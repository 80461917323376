import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { PageRoomsList } from "../components/PageRoomsList/PageRoomsList";
import { PageRoom } from "../components/PageRoom/PageRoom";


export const routes = [
  { name: 'room', path: '/room/:id' },
  { name: 'main', path: '/' },
  { name: 'compose', path: '/compose' },
  { name: 'contacts', path: '/contacts' }
]


export const getRoomPath = (roomId: number) => {
  return `/room/${roomId}`;
}


export const router = createBrowserRouter([
  {
    path: "",
    element: <PageRoomsList/>,
  },
  {
    path: "/room/:id",
    element: <PageRoom/>,
  },
]);
