import { Room } from "../types/domain/room";
import { Answer, SavedAnswer } from "../types/domain/answer";
import { AuthState } from "../store/authReducer";

export const FETCH_ROOMS = 'FETCH_ROOMS';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';

export const FETCH_ONE_ROOM = 'FETCH_ONE_ROOM';
export const FETCH_ONE_ROOM_SUCCESS = 'FETCH_ONE_ROOM_SUCCESS';

export const FETCH_ANSWER = 'FETCH_ANSWER';
export const FETCH_ANSWER_DONE = 'FETCH_ANSWER_DONE';

export const FETCH_ROOM_ANSWERS = 'FETCH_ROOM_ANSWERS';
export const FETCH_ROOM_ANSWERS_DONE = 'FETCH_ROOM_ANSWERS_DONE';

export const HANDLE_GLOBAL_ERROR = 'HANDLE_GLOBAL_ERROR';

export const HANDLE_CHECK_AUTH = 'HANDLE_CHECK_AUTH';
export const HANDLE_GET_AUTH = 'HANDLE_GET_AUTH';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const LOGOUT = 'LOGOUT';

export function fetchRooms() {
  return {
    type: FETCH_ROOMS
  }
}

export function fetchRoomsSuccess(data: Room[]) {
  return {
    type: FETCH_ROOMS_SUCCESS,
    payload: {rooms: data},
  }
}

export function fetchOneRoom(id: number) {
  return {
    type: FETCH_ONE_ROOM,
    payload: { id },
  }
}

export function fetchOneRoomSuccess(data: Room) {
  return {
    type: FETCH_ONE_ROOM_SUCCESS,
    payload: {room: data},
  }
}

export function fetchCheckAnswer(data: Answer) {
  return {
    type: FETCH_ANSWER,
    payload: { data },
  }
}

export function fetchCheckAnswerDone(data: {id: number, done: boolean}) {
  return {
    type: FETCH_ANSWER_DONE,
    payload: data,
  }
}

export function fetchRoomAnswers(roomId: number) {
  return {
    type: FETCH_ROOM_ANSWERS,
    payload: roomId,
  }
}

export function fetchRoomAnswersDone(answers: SavedAnswer[]) {
  return {
    type: FETCH_ROOM_ANSWERS_DONE,
    payload: answers,
  }
}

export function handleCheckAuth(token0?: string) {
  return {
    type: HANDLE_CHECK_AUTH,
    payload: { token0 },
  }
}

export function getAuth() {
  return {
    type: HANDLE_GET_AUTH,
  }
}

export function setAuthData(authState: AuthState) {
  return {
    type: SET_AUTH_DATA,
    payload: authState,
  }
}

export function empty() {
  return {
    type: 'EMPTY'
  }
}
