import { Reducer } from "redux";
import { SET_AUTH_DATA } from "../actions";

export interface AuthState {
  token0?: string;
  user?: {
    name: string,
    image: string,
  }
}

const getDefaultState = (): AuthState  => {
  try {
    const token0 = localStorage.getItem('token0');
      return {
        token0: token0 || undefined,
        user: undefined
      }

  } catch (e) {
    console.log('error with initial auth states', e);
  }

  return  {
    token0: undefined,
    user: undefined
  };
}

export const authReducer: Reducer<AuthState> = (
  state = getDefaultState(),
  action: any
) => {
  switch (action.type) {
    case SET_AUTH_DATA: {
      console.log('action.payload', action.payload);
      return {
        ...action.payload
      }
    }
    default:
      return state;
  }

}
